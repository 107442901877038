.header {
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    /* min-height: 7vh; */
    background-color: #000000;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    font-family: Verdana;
    z-index: 1000;
    /* align-items: baseline; */
}
.headerMode {
    width: 10%;
    color: #f2f2f2;
    margin: auto;
    margin-left: 0;
    margin-right: 0;
    text-align:left;
    padding: 14px 16px;
    text-decoration: none;
    /* font-size: 17px; */
    justify-self: flex-start;
    align-content: center;
    /* cursor: pointer; */
}
.headerImage {
    /* width: 20%; */
    color: #f2f2f2;
    margin: 0;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    /* font-size: 17px; */
    justify-self: center;
    /* cursor: pointer; */
}
.headerItem {
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    justify-self: center;
    cursor: pointer;
    align-self: center;
}
.container {
    margin: 7vh;
    margin-top: 10vh;
    display: flex;
}
.colored-circle {
    /* display: inline-block; */
    justify-self: center;
    margin: auto;
    /* margin-left: 5px;
    margin-right: 5px;
    margin-bottom: -2px; */
    border-radius: 50%;
    border-style: solid;
    border-width: 0.5px;
    border-color: black;
    height: 20px;
    width: 20px;
}