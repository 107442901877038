.chainContainer {
    flex-direction: column;
    
}
.serviceButton {
    align-self: center;
    margin: auto;
    width: max-content;
}
/* .serviceIndicator {
    width: '200px';
    height: '40px'; 
    font-weight: 'bold'; 
    font-size: '30px'
} */
.detailContainer {
    display: inline-flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-between;
    align-self: flex-start;
}
.blockDetail {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
}
.transactionButton {
    display: flex;
    flex-direction: column;
    width: 30%;
    margin: auto;
    margin-top: 20px;
}